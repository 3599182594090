<template>
    <div>
        <div :class="navBarFixed == true ? 'navBarWrap' :( navRouter == true ? 'navBarWrap2' : 'navBarWrap1')">
            <div  class="navBarWrapCon">
                <div class="menu-list">
                    <div class="logo">
                        <img src="@/assets/logo.png" alt="" v-if="!navBarFixed && !navRouter">
                        <img src="@/assets/logo1.png" alt="" v-else>
                    </div>
                    <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal">
                        <el-menu-item index="/">首页</el-menu-item>
                        <el-submenu index="/freight"> 
                            <template slot="title">产品</template>
                            <el-menu-item index="/freight">七贝智运</el-menu-item>
                            <el-menu-item index="/station">七贝站管</el-menu-item>
                            <el-menu-item index="/server">七贝车服</el-menu-item> 
                            <el-menu-item index="/suningPay">七贝易付</el-menu-item> 
                            <el-menu-item index="/monitor">七贝智联</el-menu-item> 
                            <el-menu-item index="/qibeiCloudMall">七贝云商</el-menu-item>
                            <el-menu-item index="/qibeiEnterpriseService">七贝企服</el-menu-item>
                        </el-submenu>
                        <el-submenu index="/case/tyt">
                            <template slot="title">案例</template>
                            <el-menu-item index="/case/tyt">途悠通</el-menu-item>
                            <el-menu-item index="/case/tyy">途悠e站</el-menu-item>
                            <el-menu-item index="/case/gzb">管站宝</el-menu-item> 
                            <el-menu-item index="/case/fxc">福洗车</el-menu-item> 
                            <el-menu-item index="/case/eqjy">e起加油</el-menu-item> 
                            <el-menu-item index="/case/cloudMall">七贝云商城</el-menu-item>
                            <el-menu-item index="/case/yfpay">易付聚合</el-menu-item>
                        </el-submenu>
                        <el-menu-item><a href="https://shop.ufudig.com/">商城</a></el-menu-item>
                        <el-menu-item index="/aboutUs">关于</el-menu-item>
                    </el-menu>
                </div>
            </div>
        </div>
        <div>
            <router-view></router-view>
        </div>
        <div>
            <footer-bottom></footer-bottom>
        </div> 
    </div>
</template>
<script>
import FooterBottom from "@/components/FooterBottom.vue";
export default {
  data: function() {
    return {
      activeIndex: "1",
      navBarFixed: false,
      navRouter: false
    };
  },
  components: {
    FooterBottom
  },
  watch: {
    $route(newRoute) {
      this.navRouter = this.$route.path.indexOf("case/") > -1 ? true : false;
    }
  },
  created() {
    this.navRouter = this.$route.path.indexOf("case/") > -1 ? true : false;
    
  },
  actived(){
    this.navRouter = this.$route.path.indexOf("case/") > -1 ? true : false;
  },
  mounted() { 
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //  当滚动超过 50px 时，实现吸顶效果(滚动条高度当前设置为：50px)
      if (scrollTop > 49) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    }
  }
};
</script>
<style>
.navBarWrapCon {
  width: 1920px;
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 123;
}
.navBarWrap {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 9;
  /* 　　position: fixed;
    margin: auto;
　　 top: 0;
    left: 0;
    right: 0;
　　background: #fff;
　　z-index: 9; */
}
.navBarWrap .navBarWrapCon {
  background: #fff;
  box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.25);
}

a {
  color: inherit;
  text-decoration: none;
}
.menu-list {
  display: flex;
  justify-content: space-between;
  z-index: 2;
  width: 1200px;
  height: 80px;
  line-height: 80px;
  align-items: center;
  margin: auto;
}
.logo {
  width: 120px;
  height: 54px;
}
.menu-list .el-menu.el-menu--horizontal {
  border-bottom: none;
}
.menu-list .el-menu {
  background-color: transparent;
}
.menu-list .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.menu-list .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.menu-list .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}
 
.navBarWrap1
  .menu-list
  .el-menu--horizontal
  > .el-submenu:focus
  .el-submenu__title,
.navBarWrap1
  .menu-list
  .el-menu--horizontal
  > .el-submenu:hover
  .el-submenu__title {
  color: #fff;
}
.navBarWrap1 .menu-list .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff;
}
.navBarWrap1 .menu-list .el-menu--horizontal > .el-menu-item {
  color: #fff;
}

.menu-list .el-menu.el-menu--horizontal {
  border-bottom: none;
}

.menu-list .el-dropdown-menu__item,
.el-menu-item {
  padding: 0 45px;
}
.menu-list .el-menu--horizontal > .el-menu-item,
.menu-list .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 65px;
  line-height: 65px;
}
.navBarWrap1
  .menu-list
  .el-menu--horizontal
  > .el-submenu.is-active
  .el-submenu__title {
  border-bottom: 2px solid #409eff;
  color: #fff;
}
.menu-list .el-menu--popup {
  min-width: 100px;
  text-align: center;
}

.menu-list .el-menu--horizontal .el-menu .el-menu-item.is-active,
.menu-list
  .el-menu--horizontal
  .el-menu
  .el-submenu.is-active
  > .el-submenu__title {
  background-color: #409eff;
  color: #fff;
}
.navBarWrap1
  .menu-list
  .el-menu--horizontal
  .el-menu-item:not(.is-disabled):focus,
.navBarWrap1
  .menu-list
  .el-menu--horizontal
  .el-menu-item:not(.is-disabled):hover {
  color: #fff;
}
.navBarWrap1 .menu-list .el-menu--horizontal > .el-menu-item.is-active {
  color: #fff;
}

.menu-list
  .el-menu--horizontal
  .el-menu--popup
  .el-menu-item:not(.is-disabled):hover {
  color: #303133;
}
.menu-list .el-menu-item {
  width: 120px;
  font-size: 18px;
  text-align: center
}
.menu-list .el-submenu .el-menu{
  width: 50px;
}
</style>

 
<style lang="scss"> 
.el-submenu .el-submenu__title {
  font-weight: bold;
  font-size: 16px;
  color: #1787FB;
}
.el-menu--horizontal>.el-submenu .el-submenu__title {
    height: 92px;
    line-height: 92px;
    border-bottom: 2px solid transparent;
    color: #666666;
    i{
      font-size: 16px;
      font-weight: bold;
    }
}
 
.el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    color: #1787FB;
    /* less语法，实现鼠标悬停时icon变色 */
        i {
            color: #1787FB;
        } 
} 
</style>

 