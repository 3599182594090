<template>
  <div> 
    <div class="CORPORATE——VISION">
      <div class="text-center CORPORATE——VISION-con">
        <div class="engTit color-white">COOPERATION  VISION</div>
        <div class="tit color-white">有福愿景<div class="tit-bg"></div></div>
        <div class="subtit color-white">成为一家有口皆碑的科技服务公司，为客户、员工、社会持续创造价值</div>
        <div class="VISION-btn" @click="goToAbout" style="cursor: pointer;">了解有福</div>
      </div>   
    </div> 
    <div class="FOOTER-BOTTOM">
        <div class="foot_head">
            <img src="../assets/footer/编组备份 5.png" alt="">
            <div class="btn" style="cursor: pointer;">
                <div class="button" @click="handleRouter('/')">首页</div>
                <div class="button">
                  产品
                  <div>
                    <div style="color: #6D7278;" @click="handleRouter('/freight')">七贝智运</div>
                    <div style="color: #6D7278;" @click="handleRouter('/station')">七贝站管</div>
                    <div style="color: #6D7278;" @click="handleRouter('/server')">七贝车服</div>
                    <div style="color: #6D7278;" @click="handleRouter('/suningPay')">七贝易付</div>
                    <div style="color: #6D7278;" @click="handleRouter('/monitor')">七贝智联</div>
                    <div style="color: #6D7278;" @click="handleRouter('/qibeiCloudMall')">七贝云商</div>
                    <div style="color: #6D7278;" @click="handleRouter('/qibeiEnterpriseService')">七贝企服</div>
                  </div>
                </div>
                <div class="button">案例
                  <div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/tyt')">途悠通</div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/tyy')">途悠e站</div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/gzb')">管站宝</div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/fxc')">福洗车</div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/eqjy')">e起加油</div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/cloudMall')">云商城</div>
                    <div style="color: #6D7278;" @click="handleRouter('/case/yfpay')">易付聚合</div>
                  </div>
                </div>
                <div class="button">商城</div>
                <div class="button" @click="handleRouter('/aboutUs')">关于</div>
            </div>
        </div>
        <div class="foot_cont">
            <div class="itemimg" style="margin-left: 0px;">
                <img src="../assets/footer/wx.png" alt="">
                <div class="name">微信公众号</div>
            </div>
            <div class="itemimg">
                <img src="../assets/footer/11.jpg" alt="">
                <div class="name">微信视频号</div>
            </div>
            <div class="itemimg">
                <img src="../assets/footer/有福数字（抖音）.png" alt="">
                <div class="name">抖音账号</div>
            </div>
            <div class="itemimg">
                <img src="../assets/footer/22.jpg" alt="">
                <div class="name">小红书账号</div>
            </div>
        </div>
        <div class="foot_er">
            <div class="left">陕西有福数字科技有限公司 版权所有 | <a href="https://beian.miit.gov.cn/#/Integrated/index">陕ICP备2023014456号-1</a> @Copyright 2023-2024 ufudig.com. All Rights Reserved</div>
            <div class="right">
                <div>全国服务电话：187-067-43158</div>
                <div>合作咨询： youfuyouli2024@yeah.net</div>
            </div>
        </div>
    </div>
     
  </div>
    
</template>

<script>
export default {
  methods: {
    handleRouter(router){
      this.$router.push(router)
    },
    goToAbout() {
      this.$router.push("/aboutUs");
    }
  }
};
</script>

<style scoped>
.CORPORATE——VISION {
  width: 1920px;
  margin: auto;
  text-align: center;
  height: 700px;
  box-sizing: border-box;
  background: url("../assets/imgs/qibeiQF/bz39.png") no-repeat center center;
  background-size: 100% 100%;
  color: #fff;
  overflow: hidden;
}
.VISION-btn {
  width: 160px;
  height: 48px;
  background: #0a449e;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 48px;
  margin: auto;
  position: relative;
}
.CORPORATE——VISION-con {
  margin-top: 278px;
}
.FOOTER-BOTTOM {
  background: #1a1a1a;
  /* width: 1920px; */
  height: 600px;
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 360px;
}

.foot_head {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.foot_head img {
  width: 200px;
  height: 80px;
}

.foot_head .btn {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 400px;
}

.foot_head .btn .button {
  width: 120px;
  height: 48.08px;
  line-height: 48.08px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 600;
}

.foot_cont {
  margin-top: 100.28px;
  display: flex;
}

.foot_cont .itemimg {
  width: 120px;
  text-align: center;
  margin-left: 20px;
}

.foot_cont .itemimg img {
  width: 120px;
  height: 120px;
}

.name {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 12px;
}

.foot_er {
  margin-top: 100px;
  display: flex;
  align-items: flex-end;
}

.foot_er .left {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
}

.foot_er .right {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  margin-left: 128px;
}
</style>