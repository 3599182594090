import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

import 'animate.css';
 

import "./utils/flexible"


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.css';  
 


import VueAMap from 'vue-amap'
// import '@/styles/element-variables.scss'
Vue.use(VueAMap);
Vue.use(ElementUI, {
  size: 'small',
})

VueAMap.initAMapApiLoader({
  key: 'affe234115f2f0a8f4e86fcb5d77efc7',
  plugin: [
      'AMap.Scale',
      'AMap.OverView',
      'AMap.ToolBar',
      'AMap.MapType',
      'AMap.PlaceSearch',
      'AMap.Geolocation',
      'AMap.Geocoder',
      'AMap.DrivingPolicy',
      'AMap.Driving',
  ],
  v: '1.4.4',
  uiVersion: '1.0',
})
//高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode: 'a0a8fe17dc3fda306c22871ac2a952c8',
}


new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
