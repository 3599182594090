<template>
  <div id="app">
    <!-- 其他组件 -->
    
    <router-view></router-view>
  </div>
</template>

<script>
 

export default {
  name: 'App',
  components: { 
  }
}
</script>
<style src="@/style/index.scss" lang="scss"></style> 
<style>
@import '@/style/animate.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  color: #2c3e50; 
}
</style>
