import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout'

Vue.use(VueRouter)


const constantRoutes = [
  {
    path: '/',
    redirect: '/',
    component: Layout,
    meta: {
      title: '首页',
      icon: 'financial',
    },
    children: [
      {
        path: '/',
        component: (resolve) => require(['@/views/HomePage.vue'], resolve),
        name: 'HomePage',
        meta: {
          title: '首页',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/freight',
        component: (resolve) => require(['@/views/Freight.vue'], resolve),
        name: 'monitor',
        meta: {
          title: '七贝智运',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/monitor',
        component: (resolve) => require(['@/views/Monitor.vue'], resolve),
        name: 'monitor',
        meta: {
          title: '七贝智联',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/station',
        component: (resolve) => require(['@/views/Station.vue'], resolve),
        name: 'station',
        meta: {
          title: '七贝站管',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/server',
        component: (resolve) => require(['@/views/Server.vue'], resolve),
        name: 'Server',
        meta: {
          title: '七贝车服',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/aboutUs',
        component: (resolve) => require(['@/views/AboutUsPage.vue'], resolve),
        name: 'aboutUs',
        meta: {
          title: '关于我们',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/qibeiEnterpriseService',
        component: (resolve) => require(['@/views/QibeiEnterpriseService.vue'], resolve),
        name: 'qibeiEnterpriseService',
        meta: {
          title: '七贝企服',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/suningPay',
        component: (resolve) => require(['@/views/suningPay.vue'], resolve),
        name: 'suningPay',
        meta: {
          title: '七贝易付',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: '/qibeiCloudMall',
        component: (resolve) => require(['@/views/QibeiCloudMall.vue'], resolve),
        name: 'qibeiCloudMall',
        meta: {
          title: '七贝云商',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
    ],
  },
  // 案例
  {
    path: '/case',
    redirect: '/case/tyt',
    component: Layout,
    meta: { title: '案例' },
    children: [
      {
        path: 'tyy',
        component: (resolve) => require(['@/views/case/tyy.vue'], resolve),
        name: 'tyy',
        meta: {
          title: '途悠e站',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: 'tyt',
        component: (resolve) => require(['@/views/case/tyt.vue'], resolve),
        name: 'tyt',
        meta: {
          title: '途悠通',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: 'gzb',
        component: (resolve) => require(['@/views/case/gzb.vue'], resolve),
        name: 'gzb',
        meta: {
          title: '管站宝',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: 'eqjy',
        component: (resolve) => require(['@/views/case/eqjy.vue'], resolve),
        name: 'eqjy',
        meta: {
          title: 'e起加油',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: 'fxc',
        component: (resolve) => require(['@/views/case/fxc.vue'], resolve),
        name: 'fxc',
        meta: {
          title: '福洗车',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
      {
        path: 'cloudMall',
        component: (resolve) => require(['@/views/case/cloudMall.vue'], resolve),
        name: 'cloudMall',
        meta: {
          title: '七贝云商城',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      }, 
      // yfpay
      {
        path: 'yfpay',
        component: (resolve) => require(['@/views/case/yfpay.vue'], resolve),
        name: 'yfpay',
        meta: {
          title: '聚合易付',
          icon: '',
          noCache: true,
          keepAlive: true,
        },
      },
    ],
  },

];

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRoutes,
})

export default router










