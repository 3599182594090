import { render, staticRenderFns } from "./FooterBottom.vue?vue&type=template&id=f196cef8&scoped=true"
import script from "./FooterBottom.vue?vue&type=script&lang=js"
export * from "./FooterBottom.vue?vue&type=script&lang=js"
import style0 from "./FooterBottom.vue?vue&type=style&index=0&id=f196cef8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f196cef8",
  null
  
)

export default component.exports